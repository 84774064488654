<template>
    <div class="h-screen">
        
        <!-- <div class="flex justify-between items-center mb-8 p-2">
            <img src="../../assets/image/left-arrow.svg" alt="" class="w-8 cursor-pointer" @click="backbutton">
            <p class="text-xl">Vernon Davis</p>
        </div> -->
        
        <!-- <hr class="mx-2 border-gray-500">     -->
        
        <div class="mx-2 mt-8">
            <p>Vernon Adams was a furniture restorer, woodcarver 
                and typeface designer. On May 23, 2014 Vernon was struck while riding his scooter home to see his family. 
                Vernon suffered a traumatic brain injury and lost both kidneys. After fighting to stay alive for two years, 
                Vernon transitioned on August 24th of 2016. Before his transition Vernon created royalty free fonts that 
                have been used hundreds of millions of times on websites and products; including the font you are reading 
                right now. Unfortunately Vernon's family, which includes his wife and two children, will receive any 
                continuing compensation for Vernon's creations. So now it's our time to step up. Let's help support this 
                family that lost their husband, dad, hero, and sole provider.</p>
                <p class="mt-4">To discover more about 
                Vernon Davis and the fonts he created please visit 
                    <!-- <router-link :to="{ name: 'VernonDavis'}" class="bleez-text underline">sansoxygen.com/donate</router-link>  -->
                <a
                  :href="`https://sansoxygen.com/donate`"
                  class="bleez-text underline"
                  arget="_blank"
                  >sansoxygen.com/donate</a
                >
                to share his story and donate.</p>
        </div>

    </div>
</template>

<script>
export default {
    name: 'VernonDavis',
    data(){
        return {
            showSideMenu : false,
        }
    },
    methods:{
        backbutton(){
            this.$router.go(-1);
        }
    }
}
</script>